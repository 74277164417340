import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { Body, SpecialHeader2 } from '../components/shared/Text'
import Markdown from '../components/shared/Markdown'
import LocalizedLink from '../components/localizedLink'
import { messages } from '../hooks/useMessages'

export default function PrivacyPolicy({ pageContext, data }) {
  const { strapiPrivacyPolicy } = data

  const {
    title,
    description: {
      data: { description },
    },
  } = strapiPrivacyPolicy

  const seo = {
    metaTitle: title,
    metaDescription: description,
  }

  return (
    <Layout pageContext={pageContext} onlyFooter>
      <Seo seo={seo} />
      <div className="container pt-[10vw]">
        <div className="flex flex-col justify-center gap-14 text-center lg:px-16">
          <SpecialHeader2 className="pt-10 text-center">{title}</SpecialHeader2>

          <Body className="text-center">
            <Markdown>{description}</Markdown>
          </Body>

          <Body className="text-center underline">
            <LocalizedLink to="/">
              {messages(pageContext.locale)('back')}
            </LocalizedLink>
          </Body>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($locale: String) {
    strapiPrivacyPolicy(locale: { eq: $locale }) {
      title
      description {
        data {
          description
        }
      }
    }
  }
`
